.inventory {
  position: relative !important;
  top: 0 !important;

  svg[data-icon="times-circle"] {
    display: none;
  }
}


.select {
  border-radius: 60px !important;
  box-sizing: border-box !important;
  color: #ffffff !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  margin-right:15px !important;
  margin-top: 20px !important;
  max-height:45px !important;
  padding: 4px 15px !important;
  text-transform: initial !important;

  &:hover {
    background: rgb(5, 200, 198, .6);
  }

  &:disabled {
    background: gray !important;
  }
}


.main {
  .right {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: flex-end;

    .create_quote_wrapper {
      display: flex;
      flex-direction:row-reverse;
      margin-bottom: 20px;
      padding:10px;
    }
  }
}
