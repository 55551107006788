@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:500");

.App {
  text-align: center;
  padding-bottom: 110px;
  max-width: 100% !important;
  position: relative;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  .outer {
    width: 100vw;
    height: 100vh;
    background: url("images/footer_illustration.png");
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-position: 100% 80%;
  }
  .MuiTypography-root {
    font-family: "Poppins", sans-serif;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.mainError {
  width: 80%;
  max-width: 600px;
  padding-top: 60px;
  margin: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
body {
  background-color: #f6f9fa !important;
  position: relative;
  overflow: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 800px) {
  .App {
    .outer {
      background: url("images/footer_mobile.svg");
      height: 100%;
      background-repeat: no-repeat;
      background-position: 100% 80%;
    }
  }
}

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  display: none;
}
