@import '../mobileView';

.imgDiv{
    width: 200px;
    overflow: hidden;
    img{
        height: 100%;
        width: 100%;
    }
}
.nextHeaderContainer{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    .imgDiv{
        width:30px;
    }
}
