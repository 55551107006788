@import './styleUltils';

@media(max-width: 1120px){
    .litta-date-picker__Desktoptitle{
        display: none;
    }
   
    .litta-date-picker-card {
        width: 55px !important;
        transition: none !important;
        &__date-slot{
            transition: none !important;
        }
    }
    .litta-date-picker{
        &__title {
            width: 100% !important;
            color: #000000;
            text-align: left;
            font-size: 16px !important;
        }
        .btnPrev, .btnNext{
            display: none;
        }

        &__week-arrow{
            color: black;
            width: 23px;
        }
        &__cards-container{
            width: 370px !important;
            box-sizing: border-box;
            // min-width: 300px;
            // background-color: white;
        }
        .errorMsg{
          max-width:65%;
        }
    }   
    .timeHeadBox{
        display: flex;
        justify-content: space-around;
        width: 100% !important;
        margin-left: 0px;
        column-gap: 20px;
        // background-color: red;
        margin-top: 30px !important;
        // margin-bottom: 20px;
        align-items: center !important;
        h5{
            margin: 0;
            padding: 0;
        }
    }
    .litta-slots-picker{
        &__title{
            font-size: 16px;
        }
        &__wrapper{
            width: 95% !important;
            margin-left: 2.5%;
        }
    }
    .litta-address-search{
        &__search-input-container{
            max-width: 90% !important;
            transition-duration: 0s;
            transition: none !important;
        }
        &__title{
            font-size: 16px !important;
        }
        &__results{
            max-width: 90% !important;
        }
        &__search-item {
            margin-left: 0;
        }
        
    }
    .payment-details{
        max-width: 100% !important;
        .payment-details__submit-container{
            width: 200px !important;
            height: 40px !important;
        }
        .payment-details__submit-button{
            width: 160px;
            right: 0 !important;
        }
        &__form-group {
            @include displayFlex(flex-start, center, row);
            width: 100%;
            height: 100%;
            padding-left: 0px !important;
            padding-bottom: 0px !important;
            .quoteOuter{
                .totalPrice{
                    line-height: 17px;
                    margin-bottom:2px;
                }
                .priceTag{
                    margin-top: -5px !important;
                   
                }
                .includes{
                    font-size:12px!important;
                }
            }
        }
    
    }
    .mobileSetup{
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        // background-color: red;
        h5{
            flex: 2;
            margin-left: 20px;
        }

    }
    
    .arrowHolder{
        flex: 1.5;
        display: flex;
        justify-content: center;
        column-gap: 30px;
        width: 30%;
        align-items: center;
        .arrowLeftContainer, .arrowRightContainer{
            width: 30px;
            height: 30px;
            border-radius: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(204, 204, 204);
            svg{
                fill: white !important;
            }
            &:hover{
                background-color: black;
                fill: white !important;
            }
            svg:hover{
                fill: white !important;
            }
            .arrowLeft, .arrowRight{
                width: 25px !important;
               
            }

        }
    }
    .litta-date-picker__week-arrow-container{
        display: none;
    }
    
.agree-terms {
    padding-top: 30px;
    @include displayFlex(center, center, row);
    row-gap: 10px;
    width: 100%;
    // background-color: red;
    // margin-left: 10%;
    a{
        text-decoration: none;
    }

    &__terms {
        display: flex;
        padding-left: 20px;
    }
}



.headerContainer{
    height: 80px !important;
    width: 100%;
    justify-content: center !important;
    position: static !important;
}

.litta-address-search__container {
    padding-top: 0px;
    margin-top:10px;
}
.litta-address-search__search-input-container{
    margin-top: 10px !important;
}
.agree-terms {
    padding-top: 10px;
    margin-bottom:200px;
}
.litta-slot-picker-card {
    font-size: 13px;
    font-weight: 600;
    color: #29343da3;
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    margin: 5px 5px;
    height: 36px;
    border-radius: 11px;
    background-color: #fff;
    cursor: pointer;
    transition: none !important;
    //box-shadow: inset 0 -1px #8ea8be3d, inset 0 0 0 1px #8ea8be29;
    width: 10rem !important;
    &__selected {
        color: #fff !important;
        background-color: #06cccc;
    }

    &:hover {
        background-color: #06cccc;
        transition: background-color 1s ease;
    }
}

}
