.top-quote-builder {
  align-items: center;
  display: flex;
  height: 140px;
  justify-content: center;
  position: relative;

  .header {
    position: absolute;
    left: 0;
    top: 0;
  }

  .title {
    margin: 0;
  }

  &.client-type {
    margin-top: 40px;
  }
}

.main {
    .search_bar {
      width: 100% !important;
    }

    .dismantle-items {
      display: flex;
      flex-direction: column;
    }

    .title{
        font-size:25px;
        font-weight:500;
        margin-bottom:20px;
    }
    .MuiSvgIcon-root{
        color: rgb(232, 235, 239);
    }
    .MuiStepIcon-text{
        fill: rgb(103, 114, 126);
        font-family: 'Poppins';
        font-weight: 500;
    }
    .MuiStepLabel-root{
            .Mui-active, .Mui-completed{
            color: rgb(5, 200, 198);
            .MuiStepIcon-text{
                fill: #ffffff;
            }
            }
    }
   .MuiStepLabel-labelContainer {
       .Mui-active{
           color:#000;
       }
   }
    .MuiStepLabel-label{
        font-family: 'Poppins', sans-serif;
    }
    .MuiStepConnector-root{
        left: calc(-50% + 12px);
        right: calc(50% + 12px);
        .MuiStepConnector-line{
            border-color:   #e8ebef;
            border-top-width: 2px;
        }
    }
}

.search-text{
    color: #b7b6b6;
    width: 100%;
    margin: 10px 0 5px;
    padding: 0 7px;
    font-size: 15px!important;
    text-align: center;
    padding-top: 45px;
}

.loading-outer{
    background:#05c8c6;
    width:200px;
    height:43px;
    border-radius:60px;
    color:#fff;
    display: flex;
    justify-content: center;
    align-items: center;
}


 .loading-quote{
     width: 226px;
     height: 43px;
     font-size: 16px;
     border: 1px solid rgba(51, 61, 85, 0.5);
     background-color: rgb(255, 255, 255);
     color: rgb(51, 61, 85);
     border-radius: 50px;
     display: flex;
     align-items: center;
     justify-content: center;
 }
@media screen and (max-width: 800px){
    .main{
        padding: 20 10px;
    }
    .loading-quote{
        width:300px;
    }
}

@keyframes pulse {
    0% {
      left: -200px;
    }
    100% {
      left: 0px;
    }
  }
